import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";


class ChatModal extends Component {

    render() {
        return (
            <div className="annotation-modal">
                <Modal
                    dialogClassName={"primaryModal modal-dialog annotation-modal-classname modal-dialog-scrollable"}
                    show={this.props.showChat}
                    onHide={this.props.chatCloseBtnHandler} size={this.props.size} centered={true}
                    backdrop="static">
                    <Modal.Header closeButton style={{border: 'none'}}>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe src={this.props.annotationData.chatIframeSource}
                                height="100%" width="100%" style={{minHeight: "560px"}}></iframe>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default ChatModal;