import React from "react";
import VideoComponent from "../util/VideoComponent";

const VideosComponent = (props) => {
    return (
        <div>
            <VideoComponent id="W19V328" src={process.env.PUBLIC_URL + `/videos/W19V328.mp4`}/>
            <VideoComponent id="W19V332" src={process.env.PUBLIC_URL + `/videos/W19V332.mp4`}/>
            <VideoComponent id="W19V336" src={process.env.PUBLIC_URL + `/videos/W19V336.mp4`}/>
            <VideoComponent id="W19V340" src={process.env.PUBLIC_URL + `/videos/W19V340.mp4`}/>
            <VideoComponent id="W24V441" src={process.env.PUBLIC_URL + `/videos/W24V441a.mp4`}/>

            {/*Audio*/}
            <VideoComponent id="W05A01" src={process.env.PUBLIC_URL + `/audio/W05A01.mp3`} audio="Y"/>
            <VideoComponent id="W23A01" src={process.env.PUBLIC_URL + `/audio/W23A01.mp3`} audio="Y"/>


        </div>
    )
}

export default VideosComponent;
