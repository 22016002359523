import {Color} from "three";

export const MATERIAL_PROPS_DB = [
    {
        materialName: 'WallMat',
        emmissiveIntensity: .81, //Emissive intensity
        emmisiveColor: new Color(0xFFFFFF),
        aOIntensity: 0.58, //Ambient occlusion
        envMapIntensity: .25
    },

    {
        materialName: 'CeilingMat',
        emmissiveIntensity: .4, //Emissive intensity
        emmisiveColor: new Color(0xFFFFFF),
        aOIntensity: 0.35, //Ambient occlusion
    },

    {
        materialName: 'CeilingMetalBars',
        // emmissiveIntensity:1, //Emissive intensity
        // emmisiveColor:new Color(0x000000),
        aOIntensity: .5, //Ambient occlusion
    },

    {
        materialName: 'PillarMat',
        emmissiveIntensity: .7, //Emissive intensity
        emmisiveColor: new Color(0xFFFFFF),
        aOIntensity: .61, //Ambient occlusion
    },

    {
        materialName: 'LampEmmisiveMat',
        emmissiveIntensity: .5, //Emissive intensity
        emmisiveColor: new Color(0xD9B37C),
        aOIntensity: 1, //Ambient occlusion
    },


    {
        materialName: 'FloorMat',
        /* emmissiveIntensity:.5, //Emissive intensity
         emmisiveColor:new Color(0xD9B37C),*/
        aOIntensity: .64, //Ambient occlusion
    },

    {
        materialName: 'WindowDoorMat',
        /* emmissiveIntensity:.5, //Emissive intensity
         emmisiveColor:new Color(0xD9B37C),*/
        aOIntensity: .1, //Ambient occlusion
    },

    {
        materialName: 'LampWireMat',
        emmissiveIntensity: 1.5, //Emissive intensity
        emmisiveColor: new Color(0xFFFFFF),
        // aOIntensity:.1, //Ambient occlusion
    },

    {
        materialName: 'PlinthCatalogMat',
        /*  emmissiveIntensity:null,
          emmisiveColor:null,*/
        aOIntensity: 0.1, //Ambient occlusion
    },

    {
        materialName: 'UVT43Mat',
        /*  emmissiveIntensity:null,
          emmisiveColor:null,*/
        aOIntensity: 0.25, //Ambient occlusion
    },

    {
        materialName: 'DomeFloorMat',
        /*  emmissiveIntensity:null,
          emmisiveColor:null,*/
        aOIntensity: 0.6, //Ambient occlusion
    },

    {
        materialName: 'DoorOnlyMat',
        /*  emmissiveIntensity:null,
          emmisiveColor:null,*/
        envMapIntensity: 1.6,
        aOIntensity: 1, //Ambient occlusion
    },


]
