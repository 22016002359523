
import {Euler, Vector3,Scene} from "three";

const FLOOR_NUMBER = {
    BOTTOM_FLOOR: 1,
    TOP_FLOOR:2,
};
// Here we split by Artists Names
const CameraLocationsDB = [
    // {
    //     cameraLocationId: 'W01',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 01',
    //     cameraPosition: new Vector3(-1.443, -0.263, 58.470),
    //     cameraRotation: new Euler(3.141, 0.515, 3.141),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W02',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 02',
    //     cameraPosition: new Vector3(-3.337, -0.263, 57.690),
    //     cameraRotation: new Euler(0, 0.935, 0),
    // },
    // {
    //     cameraLocationId: 'W03',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 03',
    //     cameraPosition: new Vector3(-3.579, -0.263, 55.049),
    //     cameraRotation: new Euler(0, 1.001, 0),
    // },
    // {
    //     cameraLocationId: 'W04',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 04',
    //     cameraPosition: new Vector3(-4.461, -0.263, 44.824),
    //     cameraRotation: new Euler(0.000, 1.018, 0.000),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W05',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 05',
    //     cameraPosition: new Vector3(-5.922, -0.263, 35.007),
    //     cameraRotation: new Euler(0, 0.185, 0),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W06',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 06',
    //     cameraPosition: new Vector3(-1.273, -0.263, 33.865),
    //     cameraRotation: new Euler(0, 0.009, 0),
    // },
    // {
    //     cameraLocationId: 'W07',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 07',
    //     cameraPosition: new Vector3(-3.894, -0.263, 36.865),
    //     cameraRotation: new Euler(0, -1.357, 0),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W08',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 08',
    //     cameraPosition: new Vector3(-4.094, -0.263, 54.420),
    //     cameraRotation: new Euler(0, -1.423, 0),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W09',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 09',
    //     cameraPosition: new Vector3(-2.205, -0.263, 59.632),
    //     cameraRotation: new Euler(0, -0.832, 0),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W10',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 10',
    //     cameraPosition: new Vector3(-3.905, -0.263, 28.322),
    //     cameraRotation: new Euler(0, 1.110, 0),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W11',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 11',
    //     cameraPosition: new Vector3(-6.742, -0.263, 25.796),
    //     cameraRotation: new Euler(0, 0.843, 0),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W12',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 12',
    //     cameraPosition: new Vector3(-7.737, -0.263, 22.879),
    //     cameraRotation: new Euler(0, 0.227, 0),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W13',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 13',
    //     cameraPosition: new Vector3(-3.406, -0.263, 20.493),
    //     cameraRotation: new Euler(0, -0.973, 0),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W14',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 14',
    //     // cameraPosition: new Vector3(-1.467, -0.263, 20.460),
    //     // cameraRotation: new Euler(3.142, -1.410, 3.142),  //use THREE.Euler
    //     cameraPosition: new Vector3(-1.635, -0.263, 23.414),
    //     cameraRotation: new Euler(0, -1.557, 0),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W15',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 15',
    //     cameraPosition: new Vector3(0.036, -0.263, 24.408),
    //     cameraRotation: new Euler(3.141, -0.077, 3.141),
    // },
    // {
    //     cameraLocationId: 'W16',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 16',
    //     cameraPosition: new Vector3(-5.310, -0.263, 13.225),
    //     cameraRotation: new Euler(3.141, 0.490, 3.142),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W17',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 17',
    //     cameraPosition: new Vector3(-5.366, -0.263, 13.175),
    //     cameraRotation: new Euler(0.001, 0.876, -0.000),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W18',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 18',
    //     // cameraPosition: new Vector3(-3.476, -0.263, 8.270),
    //     // cameraRotation: new Euler(0, 1.443, 0),  //use THREE.Euler
    //     cameraPosition: new Vector3(-3.976, -0.263, 5.354),
    //     cameraRotation: new Euler(0, 0.859, 0),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W19',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 19',
    //     cameraPosition: new Vector3(-3.461, -0.263, -2.302),
    //     cameraRotation: new Euler(0, 0.927, 0),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W20',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 20',
    //     cameraPosition: new Vector3(-4.201, -0.263, -12.572),
    //     cameraRotation: new Euler(0, -0.023,0),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W21',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 21',
    //     cameraPosition: new Vector3(-3.216, -0.263, -11.137),
    //     cameraRotation: new Euler(3.141, -0.893, 3.141),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W22',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 22',
    //     cameraPosition: new Vector3(-3.524, -0.263, 1.579),
    //     cameraRotation: new Euler(3.141, -0.760, 3.141),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W23',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 23',
    //     cameraPosition: new Vector3(-3.306, -0.263, 10.348),
    //     cameraRotation: new Euler(3.141, -0.810, 3.141),  //use THREE.Euler
    // },
    // {
    //     cameraLocationId: 'W24',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Wall 24',
    //     cameraPosition: new Vector3(-2.375, -0.263, 12.415),
    //     cameraRotation: new Euler(3.141, -0.293, 3.141),  //use THREE.Euler
    // },



    {
        cameraLocationId: 'W01A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Expo 2020 Entrance',
        cameraPosition: new Vector3(-3.155, -0.249, 58.980),
        cameraRotation: new Euler(3.110, 0.408, -3.130),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W02A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Undergraduate Programmes Overview',
        cameraPosition: new Vector3(-3.337, -0.263, 57.690),
        cameraRotation: new Euler(0, 0.935, 0),
    },
    {
        cameraLocationId: 'W03A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Undergraduate Programmes ',
        cameraPosition: new Vector3(-3.579, -0.263, 55.049),
        cameraRotation: new Euler(0, 1.001, 0),
    },
    {
        cameraLocationId: 'W04A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'BSc Year 1 - Urban Lab 1: Graphic Skills',
        cameraPosition: new Vector3(-4.461, -0.263, 44.824),
        cameraRotation: new Euler(0.000, 1.018, 0.000),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W04B',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'BSc Year 1 - Introducing Urban Design: Design Skills Task 1',
        cameraPosition: new Vector3(-4.306, -0.263, 42.798),
        cameraRotation: new Euler(0, 0.727, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W05A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Undergraduate Alumni Podcast',
        cameraPosition: new Vector3(-5.922, -0.263, 35.007),
        cameraRotation: new Euler(0, 0.185, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W07A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'BSc Year 2 - Urban Design: Theory to Practice',
        cameraPosition: new Vector3(-3.894, -0.263, 36.865),
        cameraRotation: new Euler(0, -1.357, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W07B',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'BSc Year 2 - Cities and Social Change',
        cameraPosition: new Vector3(-2.713, -0.263, 39.023),
        cameraRotation: new Euler(0, -1.449, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W07C',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'BSc Year 2 - Economics of Cities and their Regions',
        cameraPosition: new Vector3(-2.238, -0.263, 40.803),
        cameraRotation: new Euler(0, -1.541, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W07D',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'BSc Year 2 - Strategic Spatial Planning Project ',
        cameraPosition: new Vector3(-2.062, -0.263, 40.900),
        cameraRotation: new Euler(3.142, -1.068, 3.142),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W08A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'BSc Year 3 - Urban Design: Space and Place - Task 1 & 2',
        cameraPosition: new Vector3(-4.094, -0.263, 54.420),
        cameraRotation: new Euler(0, -1.423, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W09A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'BSc Year 3 - Real Estate Development',
        cameraPosition: new Vector3(-2.205, -0.263, 59.632),
        cameraRotation: new Euler(0, -0.832, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W10A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Reports Library - Undergraduate',
        cameraPosition: new Vector3(-3.905, -0.263, 28.322),
        cameraRotation: new Euler(0, 1.110, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W11A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Reports Library - Postgraduate',
        cameraPosition: new Vector3(-6.742, -0.263, 25.796),
        cameraRotation: new Euler(0, 0.843, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W12A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Books',
        cameraPosition: new Vector3(-7.737, -0.263, 22.879),
        cameraRotation: new Euler(0, 0.227, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W13A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        // locationName: 'PhD/MPhil Planning Studies',
        locationName: 'PhD Research - Students A-L',
        cameraPosition: new Vector3(-3.406, -0.263, 20.493),
        cameraRotation: new Euler(0, -0.973, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W14A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        // locationName: 'PhD/MPhil Planning Studies',
        locationName: 'PhD Research - Students M-Z',
        cameraPosition: new Vector3(-1.635, -0.263, 23.414),
        cameraRotation: new Euler(0, -1.557, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W15A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Research - Civic Design Exchage',
        cameraPosition: new Vector3(0.036, -0.263, 24.408),
        cameraRotation: new Euler(3.141, -0.077, 3.141),
    },
    {
        cameraLocationId: 'W15B',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Research - Evaluating Urban Design Governance',
        cameraPosition: new Vector3(-1.860, -0.263, 24.098),
        cameraRotation: new Euler(3.141, -0.752, 3.140),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W15C',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Research - Child Friendly Cities',
        cameraPosition: new Vector3(-2.933, -0.263, 26.347),
        cameraRotation: new Euler(3.141, -0.960, 3.140),  //use THREE.Euler

    },
    {
        cameraLocationId: 'W17A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate Programmes Overview',
        cameraPosition: new Vector3(-5.366, -0.263, 13.175),
        cameraRotation: new Euler(0.001, 0.876, -0.000),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W18A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Urban Design: Layout, Density and Typology',
        cameraPosition: new Vector3(-3.976, -0.263, 5.354),
        cameraRotation: new Euler(0, 0.859, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W18B',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Collaborative City Planning Strategy',
        cameraPosition: new Vector3(-3.872, -0.263, 3.324),
        cameraRotation: new Euler(3.141, 1.540, 3.141),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W19A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Urban Design: Place Making',
        cameraPosition: new Vector3(-3.461, -0.263, -2.302),
        cameraRotation: new Euler(0, 0.927, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W19B',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Urban Design: Guidance, Incentive and Control',
        // cameraPosition: new Vector3(-4.499, -0.263, -3.825),
        cameraPosition: new Vector3(-5.003, -0.263, -6.473),
        // cameraRotation: new Euler(0, 1.502, 0),  //use THREE.Euler
        cameraRotation: new Euler(0.000, 1.537, 0.000),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W19C',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Design and Real Estate',
        // cameraPosition: new Vector3(-4.499, -0.263, -3.825),
        // cameraRotation: new Euler(0, 1.502, 0),  //use THREE.Euler
        cameraPosition: new Vector3(-4.332, -0.263, -8.555),
        cameraRotation: new Euler(0.000, 1.568, 0.000),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W19D',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Sustainable Futures by Design',
        // cameraPosition: new Vector3(-4.776, -0.263, -7.103),
        // cameraRotation: new Euler(-3.142, 1.498, -3.142),  //use THREE.Euler
        cameraPosition: new Vector3(-5.338, -0.263, -11.455),
        cameraRotation: new Euler(3.137, 1.565, -3.137),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W19E',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Major Research Projects',
        // cameraPosition: new Vector3(-4.877, -0.263, -9.765),
        // cameraRotation: new Euler(3.141, 1.522, 3.141),  //use THREE.Euler
        cameraPosition: new Vector3(-0.627, -0.263, -15.288),
        cameraRotation: new Euler(0.000, -0.039,0.000),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W20A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Sustainable Urban Development Project',
        cameraPosition: new Vector3(-6.159, -0.263, -11.765),
        cameraRotation: new Euler(0, -0.014, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W20B',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Sustainable Urban Design',
        cameraPosition: new Vector3(-2.976, -0.263, -12.075),
        cameraRotation: new Euler(0, 0.044, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W20C',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Major Research Projects',
        cameraPosition: new Vector3(-2.976, -0.263, -12.075),
        cameraRotation: new Euler(0, 0.044, 0),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W21A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Plan Making Studio II',
        cameraPosition: new Vector3(-3.565, -0.263, -11.147),
        cameraRotation: new Euler(3.128, -1.543, 3.128),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W21B',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Civic Design CPD',
        cameraPosition: new Vector3(-3.565, -0.263, -11.147),
        cameraRotation: new Euler(3.128, -1.543, 3.128),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W21C',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Urban Design Research Project',
        cameraPosition: new Vector3(-3.708, -0.263, -7.225),
        cameraRotation: new Euler(3.128, -1.543, 3.128),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W21D',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Business Cases for Infrastructure',
        cameraPosition: new Vector3(-3.708, -0.263, -7.225),
        cameraRotation: new Euler(3.128, -1.543, 3.128),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W21E',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - From Strategic Vision to Urban Plan',
        cameraPosition: new Vector3(-3.750, -0.263, -2.876),
        cameraRotation: new Euler(3.104, -1.559, 3.104),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W22A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Planning for Housing: Project',
        cameraPosition: new Vector3(-3.524, -0.263, 1.579),
        cameraRotation: new Euler(3.141, -0.760, 3.141),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W22B',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - International Planning Project',
        cameraPosition: new Vector3(-3.756, -0.263, 8.256),
        cameraRotation: new Euler(2.920, -1.569, 2.920),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W23A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate Alumni Podcast',
        cameraPosition: new Vector3(-3.306, -0.263, 10.348),
        cameraRotation: new Euler(3.141, -0.810, 3.141),  //use THREE.Euler
    },
    {
        cameraLocationId: 'W24A',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Postgraduate - Sustainable Urban Mobility',
        cameraPosition: new Vector3(-2.375, -0.263, 12.415),
        cameraRotation: new Euler(3.141, -0.293, 3.141),  //use THREE.Euler
    },
];

export {CameraLocationsDB, FLOOR_NUMBER};
